<template>
  <base-section
    id="story"
    space="56"
    class="primary lighten-3"
  >
    <v-container>
      <v-row justify="space-between">
        <v-col
          cols="12"
          md="12"
        >
          <base-business-info title="SEJARAH ISTPRO" html="PT. Istpro Inti Nusa, merupakan Perusahaan Pialang dan Konsultan Asuransi, sudah berdiri selama lebih dari satu dekade pada tanggal 21 Desember 2006 di Jakarta.(2006) Langkah Pertama: Kelahiran PT. Istpro Inti Nusa PT. Istpro Inti Nusa lahir melalui Akta Notaris Rina Utami Djauhari, SH No. 10 yang dikeluarkan pada tanggal 21 Desember 2006. PT Istpro Inti Nusa tumbuh dan berkembang sebagai penyedia jasa asuransi yang handal dan inovatif.">
          </base-business-info>
          <base-btn
              :block="$vuetify.breakpoint.smAndDown"
              color="accent"
              large
              outlined
              to="sejarah-istpro"
              class ="text-capitalize"
            >
              Baca selengkapnya
            </base-btn>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionStory',
  }
</script>
